<template>
  <div>
    <ModuleComponent :module-data="moduleData" @changed="item = $event">
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input
                  name="name"
                  v-model="filters.name.value"
                  :placeholder="t('GLOBAL.NAME')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.EMAIL')">
                <b-input-group prepend="@" :label="t('GLOBAL.EMAIL')">
                  <b-form-input
                    v-model="filters.email.value"
                    type="email"
                    :placeholder="t('GLOBAL.EMAIL')"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.ROLE')">
                <v-select2
                  v-model="filters.roleId.value"
                  endPoint="roles"
                  name="roles"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.STATUS')" class="mb-3">
                <vue-select
                  v-model="filters.active.value"
                  label="text"
                  :options="[
                    { text: t('GLOBAL.ACTIVE'), value: true },
                    { text: t('GLOBAL.PASSIVE'), value: false }
                  ]"
                  :multiple="false"
                  :placeholder="t('GLOBAL.ALL')"
                >
                </vue-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template v-slot:customColumn_active="{ rowItem }">
        <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
      </template>
      <template v-slot:form="{ item }">
        <b-row>
          <b-col cols="9" sm="9" md="9">
            <b-row>
              <b-col cols="12" sm="12" md="12">
                <b-form-group :label="t('GLOBAL.NAME')">
                  <b-form-input
                    name="name"
                    v-model="item.name"
                    :placeholder="t('GLOBAL.NAME')"
                    v-validate.continues="'required|min:3'"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6" md="6">
                <b-form-group :label="t('GLOBAL.EMAIL')">
                  <b-input-group prepend="@">
                    <b-form-input
                      v-model="item.email"
                      type="email"
                      name="email"
                      :placeholder="t('GLOBAL.EMAIL')"
                      v-validate.continues="'required|email'"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6" md="6">
                <b-form-group :label="t('GLOBAL.PASSWORD')">
                  <b-input-group prepend="#">
                    <b-form-input
                      v-model="item.password"
                      type="password"
                      name="password"
                      ref="password"
                      :placeholder="t('GLOBAL.PASSWORD')"
                      v-validate="'required'"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="6" sm="6" md="6">
                <b-form-group :label="t('GLOBAL.LANGUAGE')">
                  <v-select2
                    v-model="item.languageId"
                    :text="item.language ? item.language.name : ''"
                    endPoint="languages"
                    :placeholder="t('GLOBAL.LANGUAGE')"
                  ></v-select2>
                </b-form-group>
              </b-col>
              <b-col cols="6" sm="6" md="6">
                <b-form-group :label="t('GLOBAL.ROLE')">
                  <v-select2
                    v-model="item.roleId"
                    :text="item.role ? item.role.name : ''"
                    endPoint="roles"
                    :placeholder="t('GLOBAL.ROLE')"
                  ></v-select2>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <Upload v-model="item.image" single :title="t('GLOBAL.IMAGE')" />
          </b-col>
        </b-row>
      </template>
    </ModuleComponent>
  </div>
</template>
<script>
// http://core.datadns.net:5000/swagger/index.html#model-User

export default {
  name: "users",
  inject: ["$validator"],
  data() {
    return {
      item: {},
      moduleData: {
        name: "users",
        primaryKey: "id",
        table: {
          sortBy: "email",
          sortDesc: false,
          graphqlQuery: `
							id,name,email,active,
							role{name},
							language{name}
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.EMAIL"), value: "email" },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            { text: this.t("GLOBAL.ROLE"), value: "role.name" },
            { text: this.t("GLOBAL.LANGUAGE"), value: "language.name" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active"],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            email: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            roleId: {
              type: "number",
              value: null
            },
            active: {
              type: "bool",
              value: null
            }
          }
        }
      }
    };
  }
};
</script>
